import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

export const StyledImg = styled(GatsbyImage)`
  width: 100%;
  height: 500px;
  @media screen and (max-width: 500px) {
        height: 250px;
        img {
            object-position: top;
        }
    }
`

export const Wrapper = styled.div`
  position: relative;
  padding: 4rem 0;
  @media screen and (max-width: 991px) {
        padding: 0 0 0rem;
    }
  .container{
    .columns{
        width: calc(100% + 0.75rem);
        align-items: center;
        @media screen and (max-width: 991px) {
            flex-direction: column;
            flex-wrap: wrap;
            width: 100%;
        }
        .column{
            :nth-child(1) {
                max-width: 40%;
                @media screen and (max-width: 991px) {
                    max-width: 100%;
                    width: 100%;
                }
                img {
                    width: 100%;
                    @media screen and (max-width: 991px) {
                        padding: 0;
                    }
                }
            }
            :nth-child(2) {
                @media screen and (max-width: 991px) {
                    padding-left: 0;
                    padding-right: 0;
                }
                .text-wrapper {
                    padding-left: 15%;
                    @media screen and (max-width: 991px) {
                        padding: 0;
                    }
                    h1 {
                        line-height: 1em;
                    }
                    h2{
                        margin: 0 0 1.25rem;
                    }
                    h3 {
                        color: #FF364E;
                        margin: 0 0 0.25rem;
                    }
                    p {
                        font-size: 18px;
                        color: #7A757E;
                        line-height: 1.6em;
                    }
                }
            }
        }
    }
  }
`
