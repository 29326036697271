import React from 'react';
import Layout from '../../components/Layout/Layout';
import Seo from '../../components/Seo/Seo';
import CsHero from '../../components/CreativeServices/CsHero/CsHero';
import CsIntro from '../../components/CreativeServices/CsIntro/CsIntro';
import CsSem from '../../components/CreativeServices/CsSem/CsSem';
import CsTwo from '../../components/CreativeServices/CsTwo/CsTwo';
import LetsChat from '../../components/Home/LetsChat/LetsChat';
import CsServices from '../../components/CreativeServices/CsServices/CsServices';

const CsPage = () => (
  <Layout>
    <Seo title="Creative Services" />
    <CsHero />
    <CsIntro />
    <CsSem />
    <CsServices />
    <CsTwo />
    <LetsChat />
  </Layout>
)


export default CsPage;
