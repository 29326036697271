import styled from "styled-components"

export const Wrapper = styled.div`
  position: relative;
  padding: 6rem 0 4.5rem;
  background: #000;
  color: #fff;
  margin-bottom: 6rem;
  text-align: center;
  @media screen and (max-width:767px) {
    padding-top: 4rem;
    padding-bottom: 2.5rem;
    margin-bottom: 0rem;
  }
  p {
        color: #ADADAD;
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
        font-size: 18px;
    }
  .columns{
    padding: 0 0.25rem;
    justify-content: center;
    @media screen and (max-width:767px) {
      display: block;
      margin: 0;
      padding: 0;
    }
    .column{

    }
  }
`