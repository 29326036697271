import React from "react"
import { Wrapper } from "./CsServices.styles"
import { useCsServicesQuery } from "../../../hooks/useCsServicesQuery"

const CsServices = () => {

  const {
    wpPage: { creativeServices: data },
  } = useCsServicesQuery()
  const csServices = data.csServices
  return (
    <Wrapper>
      <div className="container">
        <div className="columns">
        <div className="Cs-services-text" dangerouslySetInnerHTML={{ __html: csServices }} />
        </div>
      </div>
    </Wrapper>
  )
}

export default CsServices
