import React from "react"
import { Wrapper, StyledImg } from "./CsTwo.styles"
import { useCsTwoQuery } from "../../../hooks/useCsTwoQuery"
import { getImage } from "gatsby-plugin-image"
import { motion } from "framer-motion";

const CsTwo = () => {

  const {
    wpPage: { creativeServices: data },
  } = useCsTwoQuery()

  const imageData = getImage(data.twoImage.localFile)
  const twoLogo = getImage(data.twoLogo.localFile)
  const twoText = data.twoText
  const twoLogoAltText = data.twoLogo.altText
  const twoImageAltText = data.twoImage.altText

  return (
    <Wrapper>
      <div className="container">
      <div className="columns section-heading-columns">
          <div className="column">
            <div className="section-heading"><h2>Our Work</h2></div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <div className="heading">
              <StyledImg 
                image={twoLogo} 
                alt={twoLogoAltText} 
                objectFit="contain"
              />
            </div>
            <div className="text-section">
            <div className="two-text" dangerouslySetInnerHTML={{ __html: twoText }} />
            <ul>
              {data.twoListItems.map( (description, i) => (
                  <li key={i}>{description.listItem}</li>
              ))}
              </ul>
            </div>
          </div>
          <div className="column">
            <div className="color-mask">
            <motion.div className="color-mask-layer" 
            //initial={{ x: 600 }} 
            // animate={{ x: 0 }} 
            // transition={spring}
            //whileInView={{ x: 0 }}
            initial={{ transform: 'scaleX(0.5)' }}
            whileInView={{ transform: 'scaleX(1)' }}

            />
            <StyledImg image={imageData} alt={twoImageAltText} />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default CsTwo
