import React from "react"
import { getImage } from "gatsby-plugin-image"
import { useCsHeroQuery } from "../../../hooks/useCsHeroQuery"
import { Wrapper, StyledImg } from "./CsHero.styles"

const CsHero = () => {
  
  const {
    wpPage: { creativeServices: data },
  } = useCsHeroQuery()

  const imageData = getImage(data.hero.localFile)
  const heroAltText = data.hero.altText

  return (
    <Wrapper>
      <div className="container">
        <StyledImg image={imageData} alt={heroAltText} />
      </div>
    </Wrapper>
  )
}

export default CsHero