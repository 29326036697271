import React from "react"
import { getImage } from "gatsby-plugin-image"
import { useCsIntroQuery } from "../../../hooks/useCsIntroQuery"
import { Wrapper, StyledImg } from "./CsIntro.styles"

const CsIntro = () => {
  
  const {
    wpPage: { creativeServices: data },
  } = useCsIntroQuery()

  const introText = data.introText
  const imageData = getImage(data.introImage.localFile)
  
  return (
    <Wrapper>
      <div className="container">
        <div className="columns">
            <div className="column">
                <div dangerouslySetInnerHTML={{ __html: introText }} />
            </div>
            <div className="column">
                <StyledImg image={imageData} alt="Design your dream website" />
            </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default CsIntro
