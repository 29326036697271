import React from "react"
import { getImage } from "gatsby-plugin-image"
import { useCsSemQuery } from "../../../hooks/useCsSemQuery"
import { Wrapper, StyledImg } from "./CsSem.styles"

const CsSem = () => {
  
  const {
    wpPage: { creativeServices: data },
  } = useCsSemQuery()

  const semText = data.semText
  const imageData = getImage(data.semImage.localFile)
  const imageAlt = data.semImage.altText

  return (
    <Wrapper>
      <div className="container">
        <div className="columns">
            <div className="column">
                <StyledImg image={imageData} alt={imageAlt} />
            </div>
            <div className="column">
                <div className="text-wrapper" dangerouslySetInnerHTML={{ __html: semText }} />
            </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default CsSem
