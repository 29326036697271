import { useStaticQuery, graphql } from "gatsby"

export const useCsTwoQuery = () => {
  const data = useStaticQuery(graphql`
    query CsTwoQuery {
      wpPage(databaseId: {eq: 191}) {
        id
        creativeServices {
          twoImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1200, placeholder: BLURRED)
              }
            }
          }
          twoLogo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 365, placeholder: BLURRED)
              }
            }
          }
          twoListItems {
            listItem
          }
          twoText
        }
      }
    }
  `)

  return data
}
