import { useStaticQuery, graphql } from "gatsby"

export const useCsServicesQuery = () => {
  const data = useStaticQuery(graphql`
    query CsServiceQuery {
        wpPage(databaseId: {eq: 191}) {
            id
            creativeServices {
              csServices
              }
            }
          }
  `)

  return data
}
