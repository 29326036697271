import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"


export const StyledImg = styled(GatsbyImage)`
  width: 100%;
`

export const Wrapper = styled.div`
  position: relative;
  padding: 0rem 0 7rem;
  @media screen and (max-width: 767px) {
    padding: 0 0 2rem;
  }

  .section-heading-columns{
    padding-top: 2rem;
    display: block;
    .column{
      max-width: 100%!important;
    }
    .section-heading{
        h2{
          font-size: 96px;
          @media screen and (max-width: 767px) {
            font-size: 36px;
          }
        }
      }
  }

  > .container {
    > .columns {
      @media screen and (max-width:767px) {
        flex-direction: column-reverse;
        margin: 0;
      }
      .column{
        :nth-child(1) {
          max-width: 40%;
          @media screen and (max-width:767px) {
            max-width: 100%;
            padding: 0;
          }
        }
        :nth-child(2) {
          @media screen and (max-width:767px) {
            margin: auto;
          }
        }
      }
      .heading {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
        @media screen and (max-width: 767px) {
          margin-top: 1.5rem;
        }
        img{
          object-position: left;
          max-width: 285px;
          :nth-child(1) {
          }
        }
      }
    }
  }
  .text-section {
    p {
      font-size: 14px;
      line-height: 1.6em;
      margin: 0 0 0.75rem;
    }
    ul{
      color: #B0A9B7;
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      margin-left: -0.75rem;
      margin-right: -0.75rem;
      li {
        font-weight: 100;
        font-size: 18px;
        margin-bottom: 0.25rem;
        width: calc(50% - 1.5rem);
        padding: 0 0.75rem;
      }
    }
  }
  .color-mask {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: -2rem;
    @media screen and (max-width: 767px) {
      margin-top: 0;
    }
    .color-mask-layer {
      background: #42E2B8;
      height: 250px;
      display: block;
      position: absolute;
      mix-blend-mode: multiply;
      z-index: 2;
      transition: transform 0.3s linear;
      transform-origin: right;
      width: 100vw;
      margin-top: 6rem;
      @media screen and (max-width: 767px) {
        height: 15vw;
        margin-top: 0;
        left: 0;
        margin-left: 0;
        width: 180vw;
      }
    }
  }
`